import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ToastrModule } from "ngx-toastr";
import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule} from './shared/navbar/navbar.module';
import { FixedPluginModule} from './shared/fixedplugin/fixedplugin.module';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { MessageService} from 'primeng/api';
import { ApiPrefixInterceptor } from './@core/http/api-prefix.interceptor';
import { FilterService } from 'primeng/api';
import { HTTP_INTERCEPTORS,HttpClientModule } from '@angular/common/http';
import { JwtInterceptor} from './@core/helper/jwt.interceptor';
import { LoadingScreenInterceptor } from './@core/helper/loading.interceptors';
import { FormBuilder } from '@angular/forms';
import { LoadingScreenComponent } from './@core/loading-screen/loading-screen.component';
import { FormsModule } from "@angular/forms";
import {DropdownModule} from 'primeng/dropdown';
import { ErrorHandlerInterceptor } from "./@core/http/error-handler.interceptor";
import { CarouselModule } from 'ngx-owl-carousel-o';

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LoadingScreenComponent
  ],
  imports: [
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes,{
      useHash: false
    }),
    SidebarModule,
    FormsModule,
    NavbarModule,
    ToastrModule.forRoot(),
    FooterModule,
    HttpClientModule,
    DropdownModule,
    FixedPluginModule,
    CarouselModule,
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA],
  providers: [MessageService,FilterService,FormBuilder,
    {
      provide:HTTP_INTERCEPTORS,
      useClass:ApiPrefixInterceptor,
      multi:true
    },
    {
      provide:HTTP_INTERCEPTORS,
      useClass : LoadingScreenInterceptor,
      multi:true
    },
    { provide: HTTP_INTERCEPTORS, 
      useClass: JwtInterceptor,
      multi: true 
    },
    { provide: HTTP_INTERCEPTORS, 
      useClass: ErrorHandlerInterceptor,
      multi: true 
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
