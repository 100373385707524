import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime } from "rxjs/operators";
import { LoaderState } from '../loader';
import { LoaderService } from '../loading-screen.service';
;
@Component({
  selector: 'loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss']
})
export class LoadingScreenComponent implements OnInit, OnDestroy {
  show = false;
  private subscription: any;
  constructor(private loaderService: LoaderService) { 
  }
  ngOnInit() {
    this.subscription = this.loaderService.loaderState.pipe(
      debounceTime(200)
      ).subscribe((state: LoaderState) => {
      this.show = state.show;
      
    });
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}