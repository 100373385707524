import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { CredentialsService } from '../../auth/credentials.service';
import { environment } from 'environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private crendentialService:CredentialsService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isLoggedIn = this.crendentialService.isAuthenticated();
    const isApiUrl = request.url.startsWith(environment.serverUrl);
    if (isLoggedIn && isApiUrl) {
      var token = JSON.parse(this.crendentialService.getAccessToken());
          request = request.clone({
              setHeaders: { Authorization: `Bearer ${token.token}` }
          });
      }
    return next.handle(request);
  }
}
