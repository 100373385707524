import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError} from '@angular/router';
import { CredentialsService } from '../../auth/credentials.service';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss' ]
})
export class AdminLayoutComponent implements OnInit {

  logged:boolean = false;
  constructor(private credentialsService:CredentialsService , private route:Router)
  {
    this.route.events.subscribe((event: Event) => {
        // if (event instanceof NavigationStart) {
        // }

        if (event instanceof NavigationEnd) {
            // Hide progress spinner or progress bar
            if(this.route.url.split('?')[0] == '/login' || this.route.url.split('?')[0] == '/verify' || this.route.url.split('?')[0] == '/forgot_password' || this.route.url.split('?')[0].indexOf('change-password')>-1) {
              this.logged = false;
            }else {              
              this.logged = true;
            }
        }

        // if (event instanceof NavigationError) {
        //      // Hide progress spinner or progress bar

        //     // Present error to user
        // }
    });
  }
  

  ngOnInit() { 
    if (this.credentialsService.isAuthenticated()) {
      this.logged = true;
    } else {
      this.logged = true;
    }
    if(this.route.url.split('?')[0] == '/login' || this.route.url.split('?')[0] == '/verify' || this.route.url.split('?')[0] == '/forgot_password' || this.route.url.split('?')[0].indexOf('change-password')>-1) {
      this.logged = false;
    }

  }
}
