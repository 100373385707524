import { Injectable, Injector } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Observable, pipe } from "rxjs";
import { tap } from "rxjs/operators";
import { LoaderService } from "../loading-screen.service";
@Injectable({
  providedIn: "root",
})
export class LoadingScreenInterceptor implements HttpInterceptor {
  constructor(private loadingScreenService: LoaderService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // console.log('Loading Interceptor');
    this.showLoader();
    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.onEnd();
          }
        },
        (err: any) => {
          this.onEnd();
        }
      )
    );
  }

  private onEnd(): void {
    this.hideLoader();
  }
  private showLoader(): void {
    this.loadingScreenService.show();
  }
  private hideLoader(): void {
    this.loadingScreenService.hide();
  }
}
