import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Router, RouterStateSnapshot } from '@angular/router';
import { CredentialsService } from 'app/auth/credentials.service';
import { LoaderService } from '../loading-screen.service';

/**
 * Adds a default error handler to all requests.
 */
@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private router: Router , private credentialsService: CredentialsService , private loadingScreenService: LoaderService){

  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(map((res: any) => {
        if(res.body) {
          if (res.body.status == 'Token is Invalid' || res.body.status == 'Token is Expired' || res.body.status == 'Authorization Token not found') {
           this.logout();
            return res;
          }
        }
       
        return res;
      }),
      catchError(error => {
        if (error instanceof HttpErrorResponse  && error.status === 401) {
          this.logout();
          throw error;
        }else {
          this.errorHandler(error);
        }
        throw error;
        // return throwError(error);
      }));
  }

  // Customize the default error handler here if needed
  private errorHandler(response: HttpEvent<any>): Observable<HttpEvent<any>> {
    if (!environment.production) {
      // Do something with the error
      console.error('Request error', response);
    }
    throw response;
  }
  logout() {
    this.credentialsService.setCredentials();
    sessionStorage.removeItem('_token');
    sessionStorage.removeItem('_expiry');
    localStorage.removeItem( "_token");
    sessionStorage.removeItem('credentials');
  this.loadingScreenService.hide();
    this.router.navigate(['/login'], {
      queryParams: { redirect: '/dashboard'}
    });
}
}
