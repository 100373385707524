// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { getNameOfJSDocTypedef, getSupportedCodeFixes } from "typescript";

export const environment = {
  production: false,
  // serverUrl:"http://10.40.0.12"
  // serverUrl: 'http://3.110.51.39'
  // serverUrl: 'https://stage-admin.watchtime.in'
  serverUrl: 'https://admin.watchtime.in',
  // serverUrl: 'http://127.0.0.1:8000'
};

