import { Component, OnInit } from '@angular/core';


export interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}

export const ROUTES: RouteInfo[] = [
    { path: '/dashboard',     title: 'Dashboard',         icon:'dashboard',       class: '' },
    { path: '/articles',         title: 'Articles',             icon:'articles',    class: '' },
    { path: '/article-tags',         title: 'Article Tags',             icon:'articles',    class: '' },
    { path: '/gallery',          title: 'Gallery',              icon:'gallery',      class: '' },
    { path: '/gallery-bank',          title: 'Gallery Bank',              icon:'gallery',      class: '' },
    { path: '/authors', title: 'Authors',     icon:'authors',    class: '' },
    { path: '/banner-ads',          title: 'Banner Ads',      icon:'banner_ads',  class: '' },
    { path: '/static-pages',         title: 'Static Pages',        icon:'static_pages',    class: '' },
    { path: '/print-and-digital',    title: 'Print & digitals',        icon:'events', class: '' },
    { path: '/brands',       title: 'Brands',    icon:'brands',  class: '' },
    { path: '/glossary',       title: 'Watch Making Glossary',    icon:'watch_making_glossary',  class: '' },
    { path: '/stores',       title: 'Store Management',    icon:'store_management',  class: '' },
    { path: '/microsite',       title: 'Microsite',    icon:'sponsored',  class: '' },
    { path: '/subscribers',       title: 'Subscribers',    icon:'articles',  class: '' },
    { path: '/site-settings',       title: 'Site Settings',    icon:'site_settings',  class: '' },
    

];

@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
    styleUrls:['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
    }
}
